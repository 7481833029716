<template>
  <main :class="classes">
    <h1 class="view-sign__container view-sign__title">{{ $t('user-portal.nft_sign_title') }}</h1>
    <p class="view-sign__description">{{ $t('user-portal.nft_sign_description') }}</p>
    
    <div 
      class="view-sign__container view-sign__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <div 
      class="view-sign__container view-sign__body flow" 
      v-if="!loading">
      <ui-banner
        :description="unexpectedError.message"
        :title="unexpectedError.title"
        :intent="$pepper.Intent.DANGER"
        v-if="hasError"
      ></ui-banner>

      <forms-pins
        :errors="getErrors('pin')"
        :disabled="showForgotPin"
        :labeled="false"
        :steps="['pin']"
        @confirm="onSign"
        @input="onPinRemoveError"
        v-model="pins"
        v-if="!done"
      />

      <div 
        class="view-sign__forgot" 
        v-if="!done">
        <actions-button
          :appearance="$pepper.Appearance.DEFAULT"
          :size="$pepper.Size.S"
          @click="onForgotPinShow"
        >{{ $t('user-portal.forgot_pin_action') }}</actions-button>
      </div>

      <div 
        class="view-sign__done" 
        v-if="done">
        <ui-banner
          :title="$t('user-portal.nft_peer_sign_success_title')"
          :intent="$pepper.Intent.SUCCESS">
          <template #description>
            <div class="layout-claim__banner-descr flow">
              <p v-html="$t('user-portal.nft_peer_sign_success_description')"></p>
            
              <actions-button
                class="layout-claim__banner-btn"
                icon-post="open"
                :href="{ name: 'sayl-user-portal.home' }"
                :size="$pepper.Size.S"
              >{{ $t('user-portal.nft_sign_go_home') }}</actions-button>
            </div>
          </template>
        </ui-banner>
      </div>
    </div>

    <modal-forgot-pin
      :visible="showForgotPin"
      @close="onForgotPinClose"
      v-if="showForgotPin"
    />
  </main>
</template>

<script>
import MixinErrors from '@/helpers/errors'

import FormsPins from '@/components/forms/pins.vue'
import ModalForgotPin from '@/components/modals/forgot-pin.vue'
import UiBanner from '@/components/ui/banner.vue'

export default {
  name: 'PendingSignature',

  components: {
    FormsPins,
    ModalForgotPin,
    UiBanner,
  },

  inject: ['$nft'],

  mixins: [
    MixinErrors,
  ],

  data() {
    return {
      done: false,
      loading: false,
      showForgotPin: false,
      errors: {},

      pins: {
        pin: []
      }
    }
  },

  computed: {
    classes() {
      return {
        'container': true,
        'layout-claim__body': true,
        '-is-loading': this.loading,
        'flow': true
      }
    },

    hasError() {
      let status = this.$basil.get(this.errors, 'status', null)
      return !this.$basil.isNil(status) && status !== 422
    },

    is404Error() {
      let status = this.$basil.get(this.errors, 'status', null)
      return status === 404
    },

    is500Error() {
      let status = this.$basil.get(this.errors, 'status', null)
      return status === 500
    },

    unexpectedError() {
      let message = this.$t('user-portal.unexpected_error_message')

      if(this.is500Error) {
        message = this.$t('user-portal.sign_error_500_message')
      }

      if(this.is404Error) {
        message = this.$t('user-portal.sign_error_not_found_message')
      }

      return {
        status: this.$basil.get(this.errors, 'status'),
        title: this.$t('user-portal.unexpected_error'),
        message: message
      }
    },
  },

  methods: {
    onForgotPinShow() {
      this.showForgotPin = true
    },

    onForgotPinClose() {
      this.showForgotPin = false
    },

    onPinRemoveError() {
      this.errors = {}
    },

    async onSign() {
      try {
        this.loading = true
        await this.$nft.signForPeerTransfer({ 
          item: { pin: this.pins.pin.join('') }, 
          token: this.$route.params.token 
        })

        this.done = true
      } catch(e) {
        if(e && e.status && e.status === 404) {
          this.errors = e
        } else if(e && e.status && e.status === 422) {
          this.errors = e
        } else {
          this.handleErrors(e)
        }
      } finally {
        this.loading = false
      }
    },

    onReset() {
      this.pins = {
        pin: []
      }
      this.errors = {}
    },
  },

  mounted() {
    this.onReset()
  }
}
</script>